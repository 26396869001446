import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { respondTo } from "../_respondTo";
import { FaHome } from "react-icons/fa";
import logo from "../images/twg_logo.svg";

const NavHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 30px;
  background-color: ${(props) =>
    props.open ? "rgba(2, 15, 115, 0.7)" : "transparent"};

  ${respondTo.medium`
    padding-top: 2em;
    background-color:transparent ;
  `}
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 125%;

  ${respondTo.medium`
    flex-direction: row;
    align-items: flex-start;
    position: static;
    height: inherit;
  `}

  position: relative;
  top: ${(props) => (props.open ? "0" : "-100vh")};
  height: ${(props) => (props.open ? "100vh" : "0")};

  transition-property: top, height;
  transition-duration: 0.5s;
`;

const LinkItem = styled(Link)`
  margin: 0 0.5em;
  padding: 0.25em;
  text-decoration: none;
  color: white;
  text-align: center;

  ${respondTo.medium`
  color: rgba(2, 15, 115, 1);
  margin: 0 0.25em;
`}

  &.active {
    background-color: rgba(2, 15, 115, 1);
    color: white;
  }

  &:hover {
    text-decoration: underline;
  }

  transition-property: text-decoration;
  transition-duration: 1s;
`;

const ContactWrapper = styled.div`
  text-align: center;

  & a {
    text-decoration: none;
    color: rgba(2, 15, 115, 1);
  }
`;

const Toggle = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  ${respondTo.medium`
    display:none;
  `}
`;

const Hamburger = styled.div`
  background-color: ${(props) =>
    props.open ? "white" : "rgba(2, 15, 115, 1)"};
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? "rotate(-45deg)" : "inherit")};
  ::before,
  ::after {
    background-color: ${(props) =>
      props.open ? "white" : "rgba(2, 15, 115, 1)"};
    width: 30px;
    height: 3px;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }
  ::before {
    transform: ${(props) =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }
  ::after {
    opacity: ${(props) => (props.open ? "0" : "1")};
    transform: ${(props) => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`;

const NavLinks = (props) => {
  return (
    <LinkWrapper {...props}>
      <LinkItem to="/" aria-label="home">
        <FaHome />
      </LinkItem>
      <LinkItem to="/about-us" activeClassName="active">
        About Us
      </LinkItem>
      <LinkItem to="/finding-true-wealth" activeClassName="active">
        Finding True Wealth
      </LinkItem>
      <LinkItem to="/our-purpose" activeClassName="active">
        Our Purpose
      </LinkItem>

      <LinkItem to="/growth-strategy" activeClassName="active">
        Growth Strategy
      </LinkItem>
      <LinkItem to="/careers" activeClassName="active">
        Careers
      </LinkItem>
      <LinkItem to="/contact" activeClassName="active">
        Contact
      </LinkItem>
    </LinkWrapper>
  );
};

const Navigation = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <NavHeader open={navbarOpen ? true : false}>
      <Link to="/" style={{ marginLeft: "30px" }}>
        {" "}
        <img
          src={logo}
          style={{ height: "30px", aspectRatio: "1/1" }}
          alt="True Wealth Group logo"
        />
      </Link>
      <div>
        {navbarOpen ? <NavLinks open /> : <NavLinks />}

        <ContactWrapper>
          <p>
            <a href="mailto:info@truewg.com">info@truewg.com</a> |{" "}
            <a href="tel:01132242800">0113 224 2800</a>
          </p>
        </ContactWrapper>
      </div>
      <div style={{ width: "30px", height: "30px", marginRight: "30px" }}>
        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
      </div>
    </NavHeader>
  );
};

export default Navigation;
