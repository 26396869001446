import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const NxtButton = styled(Link)`
  color: white;
  display: inline-block;

  text-decoration: none;
  padding: 0.5em;
  border-radius: 0.25em;
  font-family: sans-serif;
  background-color: #041dd7;

  &:hover {
    background-color: #132ffb;
  }
`;

const NextButton = (props) => {
  return (
    <div style={{ textAlign: "center", padding: "2em" }}>
      <NxtButton to={props.url}>{props.name}</NxtButton>
    </div>
  );
};

export default NextButton;
