import * as React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { respondTo } from "../_respondTo";

import { StaticImage } from "gatsby-plugin-image";
import logo from "../images/twg_logo.svg";

import { PageWrapper, TextWrapper } from "../components/layout";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import YoutubeVideo from "../components/youtube-video";
import NextButton from "../components/next-button";

const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
    font-family: sans-serif;
  }
`;

const LogoWrapper = styled.div`
  margin: auto;
  width: 25%;
  max-width: 175px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  margin: auto;
  text-align: center;
  color: #020f73;
  font-family: sans-serif;
`;

// markup
const FindingTrueWealthPage = () => {
  return (
    <>
      <Helmet>
        {" "}
        <title>Finding True Wealth | True Wealth Group</title>
        <meta
          name="description"
          content="Finding true wealth with True Wealth Group"
        />
        <script defer src="https://unpkg.com/@tinybirdco/flock.js" data-host="https://api.tinybird.co" data-token="p.eyJ1IjogImI3YzA5NWQxLThiMTctNGQ5Ni04ODUwLWUxNjM0YmU5NzgyOCIsICJpZCI6ICI4Njg5NGY0MC00ZjBlLTRiMGMtYTI2YS0xMmNkZTBlYjEyOWMifQ.cXvEL-7r0R4anvPPduWPoDsUKPPZeyI54Z2BuA_L5E8"></script>
      </Helmet>
      <PageWrapper>
        <Navigation></Navigation>
        <GlobalStyle />
        <StaticImage
          alt="Background image of person walking up path on a mountain"
          src={"../images/bg-img.jpg"}
          formats={["auto", "webp", "avif"]}
          style={{
            position: "fixed",
            top: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "-1",
          }}
          loading="eager"
        />
        <LogoWrapper>
          <img
            src={logo}
            style={{ width: "100%", paddingTop: "25%", aspectRatio: "1/1" }}
            alt="True Wealth Group logo"
          />
        </LogoWrapper>{" "}
        <ContentWrapper>
          <h1>Finding True Wealth</h1>
        </ContentWrapper>
        <TextWrapper>
          <p>
            Being ‘wealthy’ means different things to different people. For most
            people, true wealth is not just about money but about leading a life
            of significance. It’s about the peace of mind that comes from
            knowing you took care of the things and people that are most
            important to you; that your life meant something; that you have a
            legacy; that your wealth brought you freedom and choice not anxiety
            and stress; that you control your money, it doesn’t control you.
          </p>{" "}
          <p>
            True financial planning wealth management can be an incredibly
            empowering service to receive and an incredibly fulfilling
            proposition to deliver. Finding your true wealth will unleash the
            potential for you to deliberately achieve the goals and objectives
            you set yourself for your future life.
          </p>{" "}
          <p>
            Put simply, as our mission statement says, we make a difference
            every day. We invest time to uncover and understand a clients’
            goals, ambitions and values. We harness technology, skill and
            creativity to deliver financial freedom and peace of mind, doing for
            clients what they would do for themselves if they had the time,
            inclination and expertise.{" "}
          </p>
          <p>
            We are privileged to be able to help our people (clients, our team,
            those who sold their businesses to us and our investors) find their
            True Wealth.
          </p>
          <p>
            No matter what stage of life you are at, one question we would urge
            you to consider is “what will you do with the time you have left in
            front of you?” This short, thought-provoking video makes you think
            about the time you have left.
          </p>
        </TextWrapper>
        <TextWrapper>
          <YoutubeVideo embedId="BOksW_NabEk" />
        </TextWrapper>
        <NextButton url="/our-purpose" name="Our Purpose" />
      </PageWrapper>
      <Footer></Footer>
    </>
  );
};

export default FindingTrueWealthPage;

export const Head = () => (
  <>
    <title>Finding True Wealth | True Wealth Group</title>
    <meta
      name="description"
      content="Finding true wealth with True Wealth Group"
    />
    <meta name="keywords" content="True Wealth Group" />
  </>
);
