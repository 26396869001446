import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const FooterBlock = styled.div`
  background-color: rgba(2, 15, 115, 1);

  padding: 2em;
  font-family: sans-serif;

  & p {
    color: white;
    text-align: center;
    font-size: 85%;
    margin: auto;
    max-width: 960px;
    margin-bottom: 0.25em;
    line-height: 1.6;
  }
`;

const Footer = () => {
  return (
    <FooterBlock>
      <p>
        True Wealth Group (TWG) is the collective name of the companies of True
        Wealth Planning Solutions limited (TWPS). TWPS is registered in England
        and Wales with company number 10012883. Registered office is located at
        Stonyroyd House, 8 Cumberland Road, Leeds, West Yorkshire LS6 2EF.
      </p>
    </FooterBlock>
  );
};

export default Footer;
